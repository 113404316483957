@use "lsg-theme";
@use "@angular/material" as mat;

:root {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
    color: lsg-theme.$dark-primary-text;

    .layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
    }

    .container {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        gap: 15px;
    }

    .table-container {
        width: 100%;
        overflow-x: auto;
    }

    button {
        border-radius: 4px;

        &.secondary {
            span.mdc-button__label, span.mat-mdc-menu-item-text {
                color: lsg-theme.$dark-primary-text;
            }
        }
    }

    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 25px;

        &.stretched {
            justify-content: space-between;
        }

        &.centered {
            justify-content: center;
        }
    }

    .button-group {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex: 0;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
    }

    .section-title {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
    }

    .text {
        font-size: 12px;
        font-weight: 400;
    }

    .text-medium {
        font-size: 12px;
        font-weight: 500;
    }

    .text-bold {
        font-size: 12px;
        font-weight: bold;
    }

    a {
        color: lsg-theme.$lsg-orange;
        cursor: pointer;
    }

    .multiple-button-col {
        --mat-switch-label-text-size: 14px;
    }

    .mdc-list-item span {
        font-size: 12px;
    }

    .custom-list {
        .mdc-list-item.mdc-list-item--with-one-line {
            height: 30px;
        }

        .mdc-list-item__primary-text {
            display: flex;
        }
    }

    .help-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .mdc-list-item--with-one-line {
            height: auto;
        }

        .mdc-list-item__primary-text {
            white-space: wrap;
        }
    }

    .attached-documents {
        .mdc-list-item__primary-text {
            display: flex;
            align-items: center;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }

    //table
    .custom-table.mat-mdc-table {
        --mat-table-header-headline-weight: bold;
        --mat-table-header-headline-size: 16px;
        --mat-table-row-item-label-text-size: 16px;

        border-collapse: collapse;
        border-radius: 4px;

        .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-header-cell, .mat-mdc-cell {
            // border: 1px solid #e0e0e0;
        }

        //.mat-mdc-header-cell, .mat-mdc-cell {
        //    max-width: 165px;
        //}

        .mdc-data-table__cell, .mdc-data-table__header-cell {
            padding: 0 16px
        }

        .mat-mdc-cell {
            background-color: #f5f5f5;
        }
    }

    mat-select {
        font-size: 14px;
    }

    mat-spinner {
        --mdc-circular-progress-active-indicator-color: white;
        width: 50px !important;
        height: 50px !important;
    }

    mat-snack-bar-container {
        --mdc-snackbar-container-color: #{lsg-theme.$lsg-light-blue};
        --mdc-snackbar-supporting-text-color: white;
        --mat-snack-bar-button-color: white;

        &.error {
            --mdc-snackbar-container-color: #{mat.get-theme-color(lsg-theme.$light-theme, error, 50)};
        }

    }

    .mat-mdc-form-field {
        width: 100%;
        font-size: 12px;
    }

    mat-tooltip-component {
        .mdc-tooltip {
            border: lightgray solid 1px;
            box-shadow: 1px 1px 2.5px lightgray;
        }

        .mat-mdc-tooltip-surface {
            border-radius: 0;
            background-color: white;
            color: lsg-theme.$dark-primary-text;
        }
    }

    mat-dialog-actions {
        display: flex;
        justify-content: space-between;

        [type='button']:last-of-type {
            margin-left: auto;
        }
    }

    .mat-button-toggle-group{
        .mat-button-toggle {
            display: flex;
            --mat-standard-button-toggle-height: 34px;
            --mat-standard-button-toggle-selected-state-background-color: #022251;
            --mat-standard-button-toggle-selected-state-text-color: white;
        }

        &.compact {
            .mat-button-toggle {
                padding: 0 2px;

                .mat-pseudo-checkbox {
                    display: none;
                }
            }
        }
    }

    @media (min-width: 960px) {
        .container {
            padding: 25px 30px;
            gap: 30px;
        }

        .title {
            font-size: 27px;
            font-weight: bold;
        }

        .section-title {
            font-size: 22px;
            font-weight: bold;
            margin: 10px 0;
        }

        .text {
            font-size: 16px;
            font-weight: 400;
        }

        .text-medium {
            font-size: 16px;
            font-weight: 500;
        }

        .text-bold {
            font-size: 16px;
            font-weight: bold;
        }

        .mdc-list-item span {
            font-size: 16px;
        }

        .multiple-button-col {
            --mat-switch-label-text-size: 16px;
        }

        .mat-mdc-form-field {
            font-size: 16px;
        }
    }

    @media (max-width: 960px) {
        .custom-table.mat-mdc-table {
            --mat-table-header-headline-size: 12px;
            --mat-table-row-item-label-text-size: 12px;
        }
    }

    @media (min-width: 1600px) {
        .container {
            padding: 40px 80px;
            gap: 40px;
        }
    }

    @media (min-width: 1981px) {
        .container {
            padding: 40px 240px;
            gap: 40px;
        }
    }

    .hidden {
        display: none;
    }

    .clickable {
        cursor: pointer;
        &:hover {
            color: lsg-theme.$lsg-orange;
        }
    }
}
