@use "../styles/lsg-theme";

.position-state {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background-color: #f9f9f9;
    font-size: 16px;
}

.group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    > :only-child {
        border-radius: 4px;
    }

    > :not(:only-child) {
        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
        }
    }
}

.main-state {
    border-left: 5px solid;
    border-left-color: inherit;
}

.positive {
    border-left-color: lsg-theme.$lsg-light-green;
}

.negative {
    border-left-color: lsg-theme.$lsg-orange;
}

.in-progress {
    border-left-color: lsg-theme.$lsg-light-purple;
}

.closed {
    border-left-color: lsg-theme.$lsg-dark-gray;
}

.reason-state {
    border-left: 5px solid;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border-top: 0;
}

.approved {
    border-left-color: lsg-theme.$lsg-light-green;
    background-color: #e9f5e1;
}

.rejected {
    border-left-color: lsg-theme.$lsg-orange;
    background-color: #fdecea;
}

.approve-reject {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.date {
    font-weight: normal;
    color: #888;
}

p {
    margin: 6px 0 0 0;
}

@media print {
    .position-state {
        border: #f9f9f9 2px solid !important;
    }
}
