@use "@angular/material" as mat;
@use "app/styles/lsg-theme";

@include mat.core();

@import "primeng/resources/themes/mdc-light-indigo/theme.css";
@import "primeng/resources/primeng.css";

@import "app/styles/award-material.scss";
@import "app/styles/award-primeng.scss";
@import "app/styles/print.scss";
@import "app/styles/history.scss";

html, body {
    height: 100%;
    margin: 0;
    padding: 0;

    @include mat.all-component-themes(lsg-theme.$light-theme);

    font-size: 12px;

    @media (min-width: 960px) {
        font-size: 16px;
    }
}
