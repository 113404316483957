@media print {
    .layout {
        overflow: visible !important;
    }

    button {
        display: none !important;
    }

    .section-title {
        margin: 0;
    }

    .container .button-row {
        display: none
    }

    .title {
        font-size: 20px !important;
    }

    .section-title {
        font-size: 15px !important;
    }

    .text {
        font-size: 10px !important;
    }

    .text-medium {
        font-size: 10px !important;
    }

    .text-bold {
        font-size: 10px !important;
    }

    .mat-mdc-row, .mdc-data-table__content, .mat-mdc-header-row {
        font-size: 10px !important;
        color: #022251 !important;
    }

    .mdc-data-table__cell, .mdc-data-table__header-cell {
        padding: 0 6px !important;
    }

    .mat-mdc-form-field {
        textarea {
            font-size: 10px !important;
        }
    }

    .mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
        box-shadow: none !important;
    }

    mat-form-field {
        display: none !important;
    }

    .attached-documents {
        .mat-mdc-list-item-unscoped-content {
            font-size: 10px !important;
        }

        .material-icons {
            font-size: 12px !important;
        }

        .mat-icon {
            height: 12px !important;
            width: 12px !important;
        }

        .mdc-list-item.mdc-list-item--with-one-line {
            height: 15px;
        }
    }
}

@page wide {
    size: A4;
}

@page {
    margin: 1.5cm 0.5cm;
}
