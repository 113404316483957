@use "lsg-theme";
@use "@angular/material" as mat;

:root {
    .p-component {
        font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
    }

    .p-datatable .p-datatable-thead > tr > th {
        padding: 0.75rem 0.25rem;
        font-weight: bold;
        color: var(--mat-table-header-headline-color);

        border-bottom-color: var(--mat-table-row-item-outline-color, var(--mat-app-outline, rgba(0, 0, 0, 0.12)));
        border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
        border-bottom-style: solid;

        &.align-right {
            text-align: right;

            > div {
                justify-content: end;
            }
        }
    }

    .p-datatable .p-datatable-tbody > tr > td {
        &.align-right {
            text-align: right;
        }
    }

    .p-button {
        color: var(--mdc-filled-button-label-text-color, var(--mat-app-on-primary));
        background: var(--mdc-filled-button-container-color, var(--mat-app-primary));
        box-shadow: none;
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        padding: 0 var(--mat-filled-button-horizontal-padding, 16px);
        height: var(--mdc-filled-button-container-height);
        letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mat-app-label-large-tracking));
        text-transform: var(--mdc-filled-button-label-text-transform);
        font-weight: var(--mdc-filled-button-label-text-weight, var(--mat-app-label-large-weight));
    }

    .p-button:not(:disabled):hover {
        opacity: 0.90;
    }

    .p-inputnumber {
        gap: 10px;
    }

    .p-inputnumber-input {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .p-inputnumber-button {
        height: 20px;
    }

    .p-inputnumber-button-group {
        gap: 4px;
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
        background: inherit;
        color: inherit;
    }

    .p-column-filter-menu-button:hover {
        background: inherit;
        color: inherit;
    }

    .p-sortable-column-icon:hover {
        background: inherit;
        color: inherit;
    }

    .p-sortable-column-badge {
        background: inherit;
        color: inherit;
    }

    .p-inputtext:enabled {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: black;
    }

    .p-inputtext:enabled:focus {
        box-shadow: inset 0 0 0 1px #000000, inset 0 0 0 1px #000000, inset 0 0 0 1px #000000, inset 0 0 0 1px #000000;
    }

    .p-inputwrapper {
        border-color: black;
    }

    .p-dropdown.p-inputwrapper-focus {
        box-shadow: inset 0 0 0 1px #000000, inset 0 0 0 1px #000000, inset 0 0 0 1px #000000, inset 0 0 0 1px #000000;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: white;
        background: lsg-theme.$lsg-group-blue;
    }
}
