@use 'sass:map';
@use '@angular/material' as mat;

$lsg-group-blue: #071D49;
$lsg-light-blue: #00AFD7;
$lsg-light-purple: #A5007D;
$lsg-light-green: #82BE0F;
$lsg-orange: #F58C00;
$lsg-dark-gray: #55555A;
$lsg-purple: #581EA3;
$custom-warn-color: #990000;

$dark-primary-text: #022251;
$light-primary-text: white;

$lsg_palettes: (
    primary: (
        0: #000000,
        10: darken($lsg-group-blue, 30%),
        20: darken($lsg-group-blue, 25%),
        25: darken($lsg-group-blue, 20%),
        30: darken($lsg-group-blue, 10%),
        35: darken($lsg-group-blue, 5%),
        40: $lsg-group-blue,
        50: lighten($lsg-group-blue, 50%),
        60: lighten($lsg-group-blue, 60%),
        70: lighten($lsg-group-blue, 70%),
        80: lighten($lsg-group-blue, 80%),
        90: lighten($lsg-group-blue, 90%),
        95: lighten($lsg-group-blue, 95%),
        98: lighten($lsg-group-blue, 98%),
        99: lighten($lsg-group-blue, 99%),
        100: #ffffff,
    ),
    secondary: (
        0: #000000,
        10: darken($lsg-light-green, 30%),
        20: darken($lsg-light-green, 25%),
        25: darken($lsg-light-green, 20%),
        30: darken($lsg-light-green, 10%),
        35: darken($lsg-light-green, 5%),
        40: $lsg-light-green,
        50: lighten($lsg-light-green, 50%),
        60: lighten($lsg-light-green, 60%),
        70: lighten($lsg-light-green, 70%),
        80: lighten($lsg-light-green, 80%),
        90: lighten($lsg-light-green, 90%),
        95: lighten($lsg-light-green, 95%),
        98: lighten($lsg-light-green, 98%),
        99: lighten($lsg-light-green, 99%),
        100: #ffffff,
    ),
    tertiary: (
        0: #000000,
        10: darken($lsg-orange, 30%),
        20: darken($lsg-orange, 25%),
        25: darken($lsg-orange, 20%),
        30: darken($lsg-orange, 10%),
        35: darken($lsg-orange, 5%),
        40: $lsg-orange,
        50: lighten($lsg-orange, 50%),
        60: lighten($lsg-orange, 60%),
        70: lighten($lsg-orange, 70%),
        80: lighten($lsg-orange, 80%),
        90: lighten($lsg-orange, 90%),
        95: lighten($lsg-orange, 95%),
        98: lighten($lsg-orange, 98%),
        99: lighten($lsg-orange, 99%),
        100: #ffffff,
    ),
    neutral: (
        0: #000000,
        4: #000a1c,
        6: #000e2b,
        10: #001847,
        12: #001c50,
        17: #002565,
        20: #002b72,
        22: #053079,
        24: #0a3580,
        25: #0c3783,
        30: #1e438f,
        35: #2d4f9c,
        40: #3a5ba9,
        50: #5574c4,
        60: #6f8edf,
        70: #8aa9fc,
        80: #b2c5ff,
        87: #ced9ff,
        90: #dae2ff,
        92: #e2e8ff,
        94: #eaedff,
        95: #eef0ff,
        96: #f2f3ff,
        98: #faf8ff,
        99: #fefbff,
        100: #ffffff,
    ),
    neutral-variant: (
        0: #000000,
        10: #191b23,
        20: #2e3038,
        25: #393b43,
        30: #45464f,
        35: #50525a,
        40: #5c5e67,
        50: #757780,
        60: #8f909a,
        70: #aaabb4,
        80: #c5c6d0,
        90: #e1e2ec,
        95: #f0f0fa,
        98: #faf8ff,
        99: #fefbff,
        100: #ffffff,
    ),
    error: (
        0: #000000,
        10: #410002,
        20: #690005,
        25: #7e0007,
        30: #93000a,
        35: #a80710,
        40: #ba1a1a,
        50: #de3730,
        60: #ff5449,
        70: #ff897d,
        80: #ffb4ab,
        90: #ffdad6,
        95: #ffedea,
        98: #fff8f7,
        99: #fffbff,
        100: #ffffff,
    ),
);

$_rest: (
    secondary: map.get($lsg_palettes, secondary),
    neutral: map.get($lsg_palettes, neutral),
    neutral-variant: map.get($lsg_palettes, neutral-variant),
    error: map.get($lsg_palettes, error),
);
$lsg_primary: map.merge(map.get($lsg_palettes, primary), $_rest);
$lsg_tertiary: map.merge(map.get($lsg_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
    color: (
        theme-type: light,
        primary: $lsg_primary,
        tertiary: $lsg_tertiary
    ),
    typography: (
        brand-family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        plain-family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        bold-weight: 700,
        medium-weight: 500,
        regular-weight: 500
    ),
    density: (
        scale: -1
    )
));
